<template lang="pug">
    v-container.custom-container()
        v-layout(column align-center)
            v-layout(column xs12='' align-center).pt-2.ma-2.mt-3.text-center
                v-img( cover max-height='300px' max-width='600px' :src="image")
                .title.mt-2(style="color: rgb(76,76,76); font-size: 2.2rem !important; letter-spacing: -1.5px !important;") {{title}}
                v-divider.primary.mt-5( style="width: 100px;")

            v-layout(column style="font-size: 15px;" ).text-xs-justify.pa-3

                p.py-2.text-justify
                  h2.mr-2 Mission Statement
                  | Our Mission is to encourage truth and integrity in our communities!
</template>

<script>
  import image from '@/assets/images/static/mission.jpg'
  export default {
    data () {
      return {
        title: 'Our Mission',
        image: image,
      }
    },

    computed: {

    },

  }
</script>

<style lang='scss' scoped>

</style>
